import { createContext, useContext, useState } from 'react'
import { getUser, loginApi, logoutApi } from '../api'

export const authContext = createContext()

export const useAuth = () => {
  const context = useContext(authContext)
  if (!context) throw new Error('There is not auth provider')
  return context
}

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('Unauthenticated')

  const login = async (creds) => {
    try {
      setLoading(true)
      const { data } = await loginApi(creds)
      if (data.user.id !== 1) throw new Error('Invalid creds')
      localStorage.setItem('email', data.user.email)
      localStorage.setItem('token', data.token)
      localStorage.setItem('token-init-data', new Date().getTime())
      setUser({
        id: data.user.id,
        name: data.user.name,
        email: data.user.email
      })
      setStatus('authenticated')
    } catch (error) {
      if (!error.response?.data) {
        setError({ code: error.code, message: error.message })
      } else {
        setError({
          code: error.response.data.code,
          message: error.response.data.message
        })
      }
    } finally {
      setLoading(false)
      setError(null)
    }
  }

  const logout = async () => {
    await logoutApi()
    setUser(null)
    setStatus('Unauthenticated')
  }

  const checkAuthtoken = async () => {
    const token = localStorage.getItem('token')
    const email = localStorage.getItem('email')
    if (!token) return await logout()

    try {
      setLoading(true)
      const { data } = await getUser(email)
      // console.log(data.user)
      //fix only administrator can enter the panel
      if (data.user.id !== 1) return await logout()
      setUser({
        id: data.user.id,
        name: data.user.name,
        email: data.user.email
      })
      setStatus('authenticated')
    } catch (error) {
      localStorage.clear()
      await logout()
    } finally {
      setLoading(false)
      setError(null)
    }
  }

  return (
    <authContext.Provider
      value={{
        login,
        logout,
        user,
        status,
        error,
        loading,
        checkAuthtoken
      }}
    >
      {children}
    </authContext.Provider>
  )
}
