import { useEffect } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useAuth } from '../contexts/authContext'
import LoginImg from '../assets/img/bg-login.jpg'
import Loader from '../components/Loader'

const Login = () => {
  const { login, error, loading } = useAuth()
  const initialValues = { email: '', password: '' }
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Correo electrónico inválido')
        .required('Correo electrónico requerido'),
      password: yup
        .string()
        .required('Contraseña requerida')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'Debe contener 8 caracteres, uno en mayúscula, uno en minúscula, un número y un carácter especial'
        )
    }),
    onSubmit: (values) => {
      login(values)
    }
  })

  useEffect(() => {
    if (error) {
      console.log(error)
      toast.error(error.message, { id: error.code })
    }
  }, [error])

  if (loading) return <Loader color={'#a27ec0'} />

  return (
    <div className="bg-white dark:bg-gray-900">
      <div className="flex justify-center h-screen">
        <div
          className="hidden bg-cover bg-center lg:block lg:w-2/3"
          style={{
            backgroundImage: `url(${LoginImg})`
          }}
        >
          <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
            <div>
              <h2 className="text-4xl font-bold text-white">
                Balance Pole Fitness
              </h2>
              <p className="max-w-xl mt-3 text-gray-300">
                Panel de administración
              </p>
              <p className="max-w-xl mt-3 text-gray-300">
                Created with 💖. &copy;Copyright {new Date().getFullYear()}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
          <div className="flex-1">
            <div className="text-center">
              <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">
                Iniciar sesión
              </h2>
            </div>
            <div>
              <form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="mt-8">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm text-gray-600 dark:text-gray-200"
                  >
                    Correo electrónico
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="nombre@dominio.com"
                    className="block w-full px-4 py-2 my-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <span className="text-sm text-red-500">
                      {formik.errors.email}
                    </span>
                  ) : null}
                </div>
                <div className="mt-6">
                  <div className="flex justify-between mb-2">
                    <label
                      htmlFor="password"
                      className="text-sm text-gray-600 dark:text-gray-200"
                    >
                      Contraseña
                    </label>
                  </div>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Ingresa tu contraseña"
                    className="block w-full px-4 py-2 my-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    onChange={formik.handleChange}
                    values={formik.values.password}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <span className="text-sm text-red-500">
                      {formik.errors.password}
                    </span>
                  ) : null}
                </div>
                <div className="mt-6">
                  <button
                    className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                    type="submit"
                  >
                    Iniciar sesión
                  </button>
                </div>
              </form>
              {/* <p className="mt-6 text-sm text-center text-gray-400">
                ¿Aun no tienes cuenta?{' '}
                <Link
                  to={'/register'}
                  className="text-blue-500 focus:outline-none focus:underline hover:underline"
                >
                  Registrar
                </Link>
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  )
}

export default Login
