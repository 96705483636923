import { Outlet } from 'react-router-dom'
import Ecommerce from './Ecommerce'

const Dashboard = () => {
  return (
    <div className="mt-20 md:mt-12">
      <div className="px-2 pt-2 md:px-10 md:pt-10">
        <div className="mb-5">
          <p className="text-3xl font-extrabold tracking-tight text-[#a27ec0]">
            Dashboard
          </p>
          <Ecommerce />
        </div>
      </div>
      <Outlet />
    </div>
  )
}

export default Dashboard
