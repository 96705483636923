import {
  createCustomer,
  createEmployee,
  createLesson,
  createPackage
} from '../api'

// Function that formats the keys of the arrays so that it works in the schedule component
export const formatKeys = (array) => {
  return array.map((item) => {
    let entries = Object.entries(item)
    let capsEntries = entries.map((entry) => [
      entry[0].charAt(0).toUpperCase() + entry[0].slice(1),
      entry[1]
    ])
    return Object.fromEntries(capsEntries)
  })
}
export const toDateTimeMySql = (date) => {
  const dateTime = new Date(date)
    .toLocaleString('es-ES', { hour12: false })
    .split(' ')
  let ymd = dateTime[0]
  const time = dateTime[1]
  ymd = ymd.split('/')
  const day =
    parseInt(ymd[0]) < 10 ? '0' + parseInt(ymd[0]).toString() : parseInt(ymd[0])
  const month =
    parseInt(ymd[1]) < 10 ? '0' + parseInt(ymd[1]).toString() : parseInt(ymd[1])
  const year = parseInt(ymd[2])
  return year + '-' + month + '-' + day + ' ' + time
}
export const toDateUser = (date) => {
  return new Date(date).toLocaleDateString('es-ES', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
    // hour12: true,
    // hour: '2-digit',
    // minute: '2-digit'
  })
}
export const getInitialStateObject = (args, isSchemaValidation = false) => {
  let o = {}
  if (isSchemaValidation) {
    args.forEach((key) => {
      if (key.field !== undefined) {
        if (key.required) {
          o = { ...o, [key.field]: key.validation }
        }
      }
    })
  } else {
    args.forEach((key) => {
      if (key.field !== undefined) {
        if (key.required) {
          o = { ...o, [key.field]: '' }
        }
      }
    })
  }
  return o
}

export const toSlug = (s) => {
  s = s.trim ? s.trim() : s.replace(/^\s+|\s+$/g, '')
  return s.toLowerCase().split(/\s+/).join('-')
}

export const formikSubmit = async (values, componentName) => {
  let response
  // console.log(componentName)
  switch (componentName) {
    case 'employees':
      response = await createEmployee({
        ...values,
        active: 1
      })
      break
    case 'customers':
      response = await createCustomer({
        ...values,
        active: 0
      })
      break
    case 'packages':
      response = await createPackage({
        ...values,
        price: Number(values.price),
        slug: toSlug(values.name),
        code: `${
          Number(values.num_classes) + Number(values.num_comp_classes)
        }-${values.num_classes}P${values.num_comp_classes}C`,
        active: 1
      })
      break
    case 'lessons':
      response = await createLesson({
        ...values,
        duration: 1,
        active: 1
      })
      break

    default:
      break
  }
  return response
}

export const getMonth = (value) => {
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ]
  return months[value]
}
