import React from 'react'
import ReactDOM from 'react-dom'
import { ContextProvider } from './contexts/ContextProvider'
import { AuthProvider } from './contexts/authContext'
import { QueryClient, QueryClientProvider } from 'react-query'

import App from './App'

import './index.css'
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnmount: true,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 1 * 60 * 1000 //5min
    }
  }
})
ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ContextProvider>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ContextProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
