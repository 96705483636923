import axios from 'axios'
import { formatKeys } from '../utils'

axios.defaults.withCredentials = true
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_URL_API
})

apiClient.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    'Content-type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`
  }

  return config
})

export const registerApi = async (creds) => {
  const { data: response } = await apiClient.post('api/register', creds)
  return response.data
}

export const loginApi = async (creds) => {
  return apiClient.get('/sanctum/csrf-cookie').then(() => {
    return apiClient
      .post('api/login', {
        email: creds.email,
        password: creds.password
      })
      .then((response) => {
        return response
      })
  })
}

export const getUser = async (email) => {
  return apiClient.get('/sanctum/csrf-cookie').then(() => {
    return apiClient
      .post('api/user', {
        email
      })
      .then((response) => {
        return response
      })
  })
}

export const logoutApi = async () => {
  const response = await apiClient.post('api/logout')
  return response
}

export const getEmployees = async () => {
  const { data } = await apiClient.get('api/employees')
  return data
}

export const getCustomers = async () => {
  const { data } = await apiClient.get('api/customers')
  return data
}

export const getDataCustomers = async () => {
  const value = 1
  const { data } = await apiClient.get(`api/customers/search/${value}`)
  return data
}

export const getPackages = async () => {
  const { data } = await apiClient.get('/api/packages')
  return data
}

export const getLessons = async () => {
  const { data } = await apiClient.get('api/lessons')
  return data
}

export const getLessonsType = async () => {
  const { data } = await apiClient.get('api/lessons')
  const types = data.filter((i) => i?.type).map((filtered) => filtered.type)
  const values = [...new Set(types)]
  return values.map((e) => {
    return {
      text: e.replace(/^\w/, (c) => c.toUpperCase()),
      value: e
    }
  })
}

export const createEmployee = async (data) => {
  const response = {}
  try {
    const { data: result } = await apiClient.post('api/employees', data)
    return { ...response, result }
  } catch (e) {
    return {
      ...response,
      code: e.code,
      message: e.response.data.errors,
      status: e.response.status
    }
  }
}

export const createCustomer = async (data) => {
  const response = {}
  try {
    const { data: result } = await apiClient.post('api/customers', data)
    return { ...response, result }
  } catch (e) {
    return {
      ...response,
      code: e.code,
      message: e.response.data.errors,
      status: e.response.status
    }
  }
}

export const createPackage = async (data) => {
  const response = {}
  try {
    const { data: result } = await apiClient.post('api/packages', data)
    return { ...response, result }
  } catch (e) {
    return {
      ...response,
      code: e.code,
      message: e.response.data.errors,
      status: e.response.status
    }
  }
}

export const createLesson = async (data) => {
  const response = {}
  try {
    const { data: result } = await apiClient.post('api/lessons', data)
    return { ...response, result }
  } catch (e) {
    return {
      ...response,
      code: e.code,
      message: e.response.data.errors,
      status: e.response.status
    }
  }
}

export const deleteEmployee = async (id) => {
  const response = {}
  try {
    const { data: result } = await apiClient.put(`api/employees/${id}`, {
      active: 0
    })
    return { ...response, result }
  } catch (e) {
    return {
      ...response,
      code: e.code,
      message: e.response.data.errors,
      status: e.response.status
    }
  }
}

export const deleteCustomer = async (id) => {
  const response = {}
  try {
    const { data: result } = await apiClient.put(`api/customers/${id}`, {
      active: 2
    })
    return { ...response, result }
  } catch (e) {
    return {
      ...response,
      code: e.code,
      message: e.response.data.errors,
      status: e.response.status
    }
  }
}

export const deletePackage = async (id) => {
  const response = {}
  try {
    const { data: result } = await apiClient.put(`api/packages/${id}`, {
      active: 0
    })
    return { ...response, result }
  } catch (e) {
    return {
      ...response,
      code: e.code,
      message: e.response.data.errors,
      status: e.response.status
    }
  }
}

export const deleteLesson = async (id) => {
  const response = {}
  try {
    const { data: result } = await apiClient.put(`api/lessons/${id}`, {
      active: 0
    })
    return { ...response, result }
  } catch (e) {
    return {
      ...response,
      code: e.code,
      message: e.response.data.errors,
      status: e.response.status
    }
  }
}

export const updateEmployee = async ({ id, updatedEmployee }) => {
  const response = {}
  try {
    const { data: result } = await apiClient.put(
      `api/employees/${id}`,
      updatedEmployee
    )
    return { ...response, result }
  } catch (e) {
    return {
      ...response,
      code: e.code,
      message: e.response.data.errors,
      status: e.response.status
    }
  }
}

export const updateCustomer = async ({ id, updatedCustomer }) => {
  // console.log(updatedCustomer)
  const response = {}
  try {
    const { data: result } = await apiClient.put(
      `api/customers/${id}`,
      updatedCustomer
    )
    return { ...response, result }
  } catch (e) {
    return {
      ...response,
      code: e.code,
      message: e.response.data.errors,
      status: e.response.status
    }
  }
}

export const updatePackage = async ({ id, updatedPackage }) => {
  // console.log(updatedPackage)
  const response = {}
  try {
    const { data: result } = await apiClient.put(
      `api/packages/${id}`,
      updatedPackage
    )
    return { ...response, result }
  } catch (e) {
    return {
      ...response,
      code: e.code,
      message: e.response.data.errors,
      status: e.response.status
    }
  }
}

export const updateLesson = async ({ id, updatedLesson }) => {
  const response = {}
  try {
    const { data: result } = await apiClient.put(
      `api/lessons/${id}`,
      updatedLesson
    )
    return { ...response, result }
  } catch (e) {
    return {
      ...response,
      code: e.code,
      message: e.response.data.errors,
      status: e.response.status
    }
  }
}

export const getSchedules = async () => {
  const { data } = await apiClient.get('api/schedules')
  return formatKeys(data)
}

export const getSubscriptions = async () => {
  const { data } = await apiClient.get('api/subscriptions')
  return data
}

export const createEvent = async (data) => {
  const { data: response } = await apiClient.post('api/schedules', data)
  return response.data
}

export const updateEvent = async ({ id, updatedEvent }) => {
  const { data: response } = await apiClient.put(
    `api/schedules/${id}`,
    updatedEvent
  )
  return response.data
}

export const deleteEvent = async (id) => {
  const { data: response } = await apiClient.delete(`api/schedules/${id}`)
  return response.data
}

export const getSubsXSchedules = async () => {
  const { data } = await apiClient.get('api/subsxschedules')
  return data
}

export const getSchedulesXSub = async (id) => {
  const { data } = await apiClient.get(`api/subsxschedules/${id}`)
  return data
}
