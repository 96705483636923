import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  Search,
  Toolbar,
  Filter,
  Page,
  Edit,
  Inject
} from '@syncfusion/ej2-react-grids'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { getSubscriptions } from '../api'
import { Header, Loader } from '../components'
import { ordersGrid } from '../data/dummy'
import { toDateUser } from '../utils'

const y = new Date()
const endDate = new Date(y.getFullYear(), y.getMonth() + 1, 0)

const Orders = () => {
  const { data, isLoading, isSuccess } = useQuery(
    ['subscriptions'],
    getSubscriptions
  )

  if (isLoading) return <Loader />
  return (
    <div className="mt-20 md:mt-12 m-2 md:m-10 p-5 md:p-10 bg-white rounded-3xl">
      <Header category="Módulo" title="Suscripciones" />
      {isSuccess ? (
        <GridComponent
          id="gridcomp"
          dataSource={data.map((item) => {
            return {
              ...item,
              package:
                item.package.length +
                ' ' +
                item.package.map((e) => {
                  return e.name.split(' ')[0]
                }),
              link: (
                <Link
                  to={`/dashboard/suscripciones/${item.id}`}
                  className="text-white py-1 px-2 capitalize rounded-2xl text-md cursor-pointer"
                  style={{ background: '#6cb36c' }}
                >
                  Ver clases
                </Link>
              ),
              // endDate: toDateUser(
              //   dayjs(item.created_at).add(24, 'day').toString()
              // )
              endDate: toDateUser(endDate)
            }
          })}
          allowPaging
          allowSorting
          toolbar={['Search']}
          width="auto"
        >
          <ColumnsDirective>
            {ordersGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject
            services={[Edit, Filter, Page, Resize, Search, Sort, Toolbar]}
          />
        </GridComponent>
      ) : (
        'Error :pochillo: no es posible realizar el fetch, el backend esta abajo'
      )}
    </div>
  )
}

export default Orders
