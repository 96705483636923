import dayjs from 'dayjs'
import es from 'dayjs/locale/es-mx'

const CardSubscription = (props) => {
  const { date, location, name } = props
  const color = location === 'Salon 1' ? 'purple-500' : 'pink-500'

  // console.log(props)

  return (
    <li className={`border-l-2 border-${color} w-full`}>
      <input
        type="hidden"
        className="bg-purple-500 text-purple-500 border-purple-500"
      />
      <input
        type="hidden"
        className="bg-pink-500 text-pink-500 border-pink-500"
      />
      <div className="md:flex flex-start w-full justify-start print:justify-center">
        <div
          className={`bg-${color} w-6 h-6 flex items-center justify-center rounded-full -ml-3.5`}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            className="text-white w-3 h-3"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm64-192c0-8.8 7.2-16 16-16h288c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-64zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
            ></path>
          </svg>
        </div>
        <div className="block p-6 rounded-lg shadow-lg bg-gray-100 mb-3 md:mb-10">
          <div className="flex flex-col md:flex-row justify-between mb-4 w-full">
            <a
              href="#!"
              className={`font-medium text-${color} hover:text-purple-700 focus:text-purple-800 duration-300 transition ease-in-out text-sm`}
            >
              {name}
            </a>
            <a
              href="#!"
              className={`font-medium text-${color} hover:text-purple-700 focus:text-purple-800 duration-300 transition ease-in-out text-sm`}
            >
              {dayjs(date).locale(es).format('dddd, D MMMM, h:mm A')}
            </a>
          </div>
          <button
            type="button"
            className={`inline-block px-4 py-1.5 bg-${color} text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out`}
            data-mdb-ripple="true"
          >
            {location}
          </button>
        </div>
      </div>
    </li>
  )
}

export default CardSubscription
