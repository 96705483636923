const Header = ({ category, title }) => {
  return (
    <div className="mb-8">
      <p className="text-gray-400">{category}</p>
      <p className="text-2xl md:text-3xl lg:text-4xl font-extrabold tracking-tight text-[#a27ec0]">
        {title}
      </p>
    </div>
  )
}

export default Header
