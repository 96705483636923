import { useQuery, useQueryClient } from 'react-query'
import { Disclosure, Tab } from '@headlessui/react'
import { BsChevronDown } from 'react-icons/bs'
import { Header, Loader } from '../components'
import { getSubsXSchedules } from '../api'
import dayjs from 'dayjs'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Program = () => {
  const queryClient = useQueryClient()

  const { data, isLoading, isSuccess } = useQuery(
    ['subsXschedules'],
    getSubsXSchedules
  )

  if (isLoading) return <Loader />

  const { schedules } = data

  const salon1 = schedules
    .filter(
      (schedule) =>
        dayjs(schedule.startTime).diff(
          dayjs().endOf('month').toDate(),
          'month'
        ) === 0 &&
        schedule.location.toString().toLowerCase().trim() === 'salon 1'
    )
    .map((e) => e)

  const salon2 = schedules
    .filter(
      (schedule) =>
        dayjs(schedule.startTime).diff(
          dayjs().endOf('month').toDate(),
          'month'
        ) === 0 &&
        schedule.location.toString().toLowerCase().trim() === 'salon 2'
    )
    .map((e) => e)

  salon1.sort((a, b) => new Date(a.startTime) - new Date(b.startTime))
  salon2.sort((a, b) => new Date(a.startTime) - new Date(b.startTime))

  return (
    <div className="mt-20 md:mt-12 m-2 md:m-10 p-5 md:p-10 bg-white rounded-3xl">
      <Header category="Módulo" title="Programa del día" />
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
          {['Salón 1', 'Salón 2'].map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel
            className={classNames(
              'rounded-xl bg-white p-3',
              'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
            )}
          >
            <div className="w-full px-4">
              <div className="mx-auto w-full rounded-2xl bg-white p-2">
                {isSuccess &&
                  salon1.map((e) => (
                    <Disclosure key={e.id}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                            <span>{`${e.subject} - ${new Date(
                              e.startTime
                            ).toLocaleDateString('es-ES', {
                              weekday: 'long',
                              month: 'long',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric'
                            })} (${e.subs.length})`}</span>
                            <BsChevronDown
                              className={`${
                                open ? 'rotate-180 transform' : ''
                              } h-5 w-5 text-purple-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 ml-8">
                            <ul className="list-disc">
                              {e.subs.map((i) => (
                                <li key={i.id}>{i.name}</li>
                              ))}
                            </ul>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel
            className={classNames(
              'rounded-xl bg-white p-3',
              'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
            )}
          >
            <div className="w-full px-4">
              <div className="mx-auto w-full rounded-2xl bg-white p-2">
                {isSuccess &&
                  salon2.map((e) => (
                    <Disclosure key={e.id}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                            <span>{`${e.subject} - ${new Date(
                              e.startTime
                            ).toLocaleDateString('es-ES', {
                              weekday: 'long',
                              month: 'long',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit'
                            })} (${e.subs.length})`}</span>
                            <BsChevronDown
                              className={`${
                                open ? 'rotate-180 transform' : ''
                              } h-5 w-5 text-purple-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 ml-8">
                            <ul className="list-disc">
                              {e.subs.map((i) => (
                                <li key={i.id}>{i.name}</li>
                              ))}
                            </ul>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
              </div>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default Program
