import { useEffect } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { FiSettings } from 'react-icons/fi'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'

import { Loader, Navbar, Sidebar, ThemeSettings } from './components'
import {
  Calendar,
  Customers,
  Dashboard,
  Employees,
  Orders,
  Login,
  Packages,
  Lessons,
  Program,
  SubscriptionDetail
} from './pages'
import { useStateContext } from './contexts/ContextProvider'
import { useAuth } from './contexts/authContext'
import './App.css'

const App = () => {
  const { user, checkAuthtoken, status, loading } = useAuth()

  const {
    activeMenu,
    themeSettings,
    setThemeSettings,
    currentColor,
    currentMode,
    setCurrentMode
  } = useStateContext()

  useEffect(() => {
    checkAuthtoken()
  }, [])

  useEffect(() => {
    if (localStorage.getItem('themeMode')) {
      setCurrentMode(localStorage.getItem('themeMode'))
    }
  }, [])

  if (loading) return <Loader color={'#a27ec0'} />

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter basename="/app">
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
            <TooltipComponent content={'Settings'} position={'Top'}>
              <button
                type="button"
                className="text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white"
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: '50%' }}
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>
          {user && activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={`dark:bg-main-dark-bg bg-main-bg min-h-screen w-full ${
              user && activeMenu ? 'md:ml-72' : 'flex-2'
            }`}
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
              {user && <Navbar user={user} />}
            </div>
            {themeSettings && <ThemeSettings />}

            <Routes>
              {status === 'Unauthenticated' ? (
                <>
                  <Route path={'/login'} element={<Login />} />
                  <Route path={'/*'} element={<Navigate to="/login" />} />
                </>
              ) : (
                <>
                  <Route path="/*" element={<Navigate to="/dashboard" />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/dashboard/suscripciones" element={<Orders />} />
                  <Route
                    path="/dashboard/suscripciones/:id"
                    element={<SubscriptionDetail />}
                  />
                  <Route path="/dashboard/paquetes" element={<Packages />} />
                  <Route path="/dashboard/empleados" element={<Employees />} />
                  <Route path="/dashboard/clientes" element={<Customers />} />
                  <Route path="/dashboard/clases" element={<Lessons />} />
                  <Route path="/dashboard/horarios" element={<Calendar />} />
                  <Route path="/dashboard/programa" element={<Program />} />
                </>
              )}
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </div>
  )
}

export default App
