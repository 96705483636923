import { useState } from 'react'
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  Filter,
  GridComponent,
  Inject,
  Page,
  Resize,
  Search,
  Sort,
  Toolbar
} from '@syncfusion/ej2-react-grids'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { deleteCustomer, getCustomers, updateCustomer } from '../api'
import { Header, Loader, DialogForm } from '../components'
import { customersGrid } from '../data/dummy'
import { toDateUser } from '../utils'
import toast from 'react-hot-toast'

const y = new Date()
const endDate = new Date(y.getFullYear(), y.getMonth() + 1, 0)

const Customers = () => {
  const queryClient = useQueryClient()
  const [isOpen, setIsOpen] = useState(false)
  const { data, isLoading, isSuccess } = useQuery(['customers'], getCustomers)

  const { isLoading: isLoadingDelete, mutate: mutateDelete } = useMutation(
    (id) => deleteCustomer(id),
    {
      mutationKey: 'deleteCustomer',
      onSuccess: ({ result }) => {
        toast.success(`Se eliminó correctamente ${result.name}`)
        queryClient.invalidateQueries(['customers'])
      },
      onError: (error) => {
        console.log(error)
        toast.error(
          `Error: ${error.code}\n Description: ${error.response.data.message}`
        )
      }
    }
  )

  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(
    (data) => updateCustomer(data),
    {
      mutationKey: 'updateCustomer',
      onSuccess: ({ result }) => {
        toast.success(`Se actualizó correctamente ID: ${result.id}`)
        queryClient.invalidateQueries(['customers'])
      },
      onError: (error) => {
        console.log(error)
        toast.error(
          `Error: ${error.code}\n Description: ${error.response.data.message}`
        )
      }
    }
  )

  // const openModal = () => setIsOpen(true)

  const dialogEdit = (props) => {
    return <DialogForm {...props} />
  }

  const handleRequest = (args) => {
    switch (args.requestType) {
      case 'delete':
        args.data.forEach((i) => {
          mutateDelete(i.id)
        })
        break
      case 'save':
        console.log(args.data)
        const id = args.data.id
        const updatedCustomer = {
          name: args.data?.name,
          email: args.data?.email,
          phone: args.data?.phone,
          active: args.data?.active === 'Activo' ? 1 : 0
        }
        mutateUpdate({ id, updatedCustomer })
        break

      default:
        break
    }
  }

  if (isLoading || isLoadingDelete || isLoadingUpdate) return <Loader />

  return (
    <div className="mt-20 md:mt-12 m-2 md:m-10 p-5 md:p-10 bg-white rounded-3xl">
      <Header category="Módulo" title="Clientes" />
      {/* <div className="mb-3">
        <button
          id="create-new-customer"
          title="createNew"
          className="w-auto px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#a27ec0] rounded-md hover:bg-purple-400 focus:outline-none focus:bg-purple-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 mr-2"
          onClick={openModal}
        >
          Nuevo cliente
        </button>
        {isSuccess && (
          <ModalForm
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            dataFields={customersGrid}
            componentName={'customers'}
            headerText={'clientes'}
          />
        )}
      </div> */}
      {isSuccess ? (
        <GridComponent
          dataSource={data
            .filter((i) => i.active.toString() !== '2')
            .map((item) => {
              return {
                ...item,
                phone: item.phone.replace(/\s/g, ''),
                active:
                  item.active.toString() === '1'
                    ? 'Activo'
                    : item.active.toString() === '2'
                    ? 'Vencido'
                    : 'Pago pendiente',
                created_at: toDateUser(item.created_at),
                endDate: toDateUser(endDate)
              }
            })}
          allowPaging
          allowSorting
          toolbar={['Delete', 'Search', 'Edit']}
          width="auto"
          editSettings={{
            allowDeleting: true,
            allowEditing: true,
            mode: 'Dialog',
            template: dialogEdit
          }}
          actionComplete={handleRequest}
        >
          <ColumnsDirective>
            {customersGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject
            services={[Edit, Filter, Page, Resize, Search, Sort, Toolbar]}
          />
        </GridComponent>
      ) : (
        'Error :pochillo: no es posible realizar el fetch, el backend esta abajo'
      )}
    </div>
  )
}

export default Customers
