import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
import { useState } from 'react'

const DialogForm = (props) => {
  const [data] = useState(Object.assign({}, props))
  const handleChange = ({ value }) => {
    // console.log(value)
    return value === 'Activo' ? 1 : 0
  }
  return (
    <div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <DropDownListComponent
            id="active"
            value={data.active}
            dataSource={['Activo', 'Pago pendiente']}
            fields={{ text: 'Estatus', value: 'Estatus' }}
            placeholder="Estatus de cliente"
            popupHeight="300px"
            floatLabelType="Always"
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  )
}

export default DialogForm
