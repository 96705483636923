const info = [
  {
    text: 'name',
    es: 'Nombre'
  },
  {
    text: 'email',
    es: 'Correo electrónico'
  },
  {
    text: 'phone',
    es: 'Teléfono'
  },
  {
    text: 'code',
    es: 'Código'
  },
  {
    text: 'description',
    es: 'Descripción'
  },
  {
    text: 'price',
    es: 'Precio'
  },
  {
    text: 'num_classes',
    es: 'Número de clases pole'
  },
  {
    text: 'num_classes_a',
    es: 'Número de clases aerial'
  },
  {
    text: 'num_comp_classes',
    es: 'Número de clases complementarias'
  },
  {
    text: 'type',
    es: 'Tipo'
  },
  {}
]
export const translate = (text) => {
  const { es } = info.find((i) => i.text === text)
  return es
}
