import { BounceLoader } from 'react-spinners'

const Loader = () => {
  return (
    <div
      className="flex justify-center items-center relative bg-main-dark-bg h-screen w-full"
      style={{ zIndex: 10001 }}
    >
      <BounceLoader color={'#a27ec0'} />
    </div>
  )
}

export default Loader
