import { useState } from 'react'
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  Filter,
  GridComponent,
  Inject,
  Page,
  Resize,
  Search,
  Sort,
  Toolbar
} from '@syncfusion/ej2-react-grids'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import toast from 'react-hot-toast'
import { deletePackage, getPackages, updatePackage } from '../api'
import { Header, Loader, ModalForm } from '../components'
import { packagesGrid } from '../data/dummy'

const Packages = () => {
  const queryClient = useQueryClient()
  const [isOpen, setIsOpen] = useState(false)
  const { data, isLoading, isSuccess } = useQuery(['packages'], getPackages)

  const { isLoading: isLoadingDelete, mutate: mutateDelete } = useMutation(
    (id) => deletePackage(id),
    {
      mutationKey: 'deletePackage',
      onSuccess: ({ result }) => {
        toast.success(`Se eliminó correctamente ${result.name}`)
        queryClient.invalidateQueries(['packages'])
      },
      onError: (error) => {
        console.log(error)
        toast.error(
          `Error: ${error.code}\n Description: ${error.response.data.message}`
        )
      }
    }
  )

  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(
    (data) => updatePackage(data),
    {
      mutationKey: 'updatePackage',
      onSuccess: ({ result }) => {
        toast.success(`Se actualizó correctamente ID: ${result.id}`)
        queryClient.invalidateQueries(['packages'])
      },
      onError: (error) => {
        console.log(error)
        toast.error(
          `Error: ${error.code}\n Description: ${error.response.data.message}`
        )
      }
    }
  )

  const openModal = () => setIsOpen(true)

  const handleRequest = (args) => {
    // console.log(args.data)
    switch (args.requestType) {
      case 'delete':
        args.data.forEach((i) => {
          mutateDelete(i.id)
        })
        break
      case 'save':
        const id = args.data?.id
        const updatedPackage = {
          name: args.data?.name,
          price: +args.data?.price.split('$')[1].split('MXN')[0],
          description: args.data?.description
        }
        mutateUpdate({ id, updatedPackage })
        break

      default:
        break
    }
  }

  if (isLoading || isLoadingDelete || isLoadingUpdate) return <Loader />

  return (
    <div className="mt-20 md:mt-12 m-2 md:m-10 p-5 md:p-10 bg-white rounded-3xl">
      <Header category="Módulo" title="Paquetes" />
      <div className="mb-3">
        <button
          id="create-new-package"
          title="createNew"
          className="w-auto px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#a27ec0] rounded-md hover:bg-purple-400 focus:outline-none focus:bg-purple-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 mr-2"
          onClick={openModal}
        >
          Nuevo paquete
        </button>
        {isSuccess && (
          <ModalForm
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            dataFields={packagesGrid}
            componentName={'packages'}
            headerText={'paquete'}
          />
        )}
      </div>
      {isSuccess ? (
        <GridComponent
          dataSource={data.map((item) => {
            return {
              ...item,
              // active: item.active === 1 ? 'Activo' : 'Baja',
              price: `$${item.price}MXN`
            }
          })}
          allowPaging
          allowSorting
          toolbar={['Delete', 'Search']}
          width="auto"
          editSettings={{ allowDeleting: true, allowEditing: true }}
          actionComplete={handleRequest}
        >
          <ColumnsDirective>
            {packagesGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject
            services={[Edit, Filter, Page, Resize, Search, Sort, Toolbar]}
          />
        </GridComponent>
      ) : (
        'Error :pochillo: no es posible realizar el fetch, el backend esta abajo'
      )}
    </div>
  )
}

export default Packages
