import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AiOutlineLogout, AiOutlineMenu } from 'react-icons/ai'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { useStateContext } from '../contexts/ContextProvider'
import { useAuth } from '../contexts/authContext'
import avatar from '../data/avatar.jpg'

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position={'BottomCenter'}>
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
)

const Navbar = ({ user }) => {
  const { loading, logout } = useAuth()
  const navigate = useNavigate()
  const {
    activeMenu,
    setActiveMenu,
    isClicked,
    setIsClicked,
    handleClick,
    screenSize,
    setScreenSize,
    currentColor
  } = useStateContext()

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth)
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false)
    } else {
      setActiveMenu(true)
    }
  }, [screenSize])

  const handleLogout = async () => {
    try {
      await logout()
      localStorage.clear()
      navigate('/login')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="flex justify-between p-2 md:mx-6 relative print:hidden">
      <NavButton
        title={'Menu'}
        customFunc={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
        color={currentColor}
        icon={<AiOutlineMenu />}
      />
      <div className="flex">
        <TooltipComponent content={'Usuario'} position={'BottomCenter'}>
          <div className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg">
            <img
              className="rounded-full w-8 h-8"
              src={!loading && user.photoURL ? user.photoURL : avatar}
              alt=""
            />
            <p>
              <span className="text-gray-400 text-base">Hola, </span>{' '}
              <span className="text-gray-400 text-base ml-1">
                {!loading && user.name ? user.name : user?.email}
              </span>
            </p>
            {/* <MdKeyboardArrowDown className="text-gray-400 text-14" /> */}
          </div>
        </TooltipComponent>
        <NavButton
          title={'Salir'}
          dotColor={'#03c9d7'}
          customFunc={handleLogout}
          color={currentColor}
          icon={<AiOutlineLogout />}
        />

        {/* {isClicked.cart && <Cart />}
        {isClicked.chat && <Chat />}
        {isClicked.notification && <Notification />}
        {isClicked.userProfile && <UserProfile />} */}
      </div>
    </div>
  )
}

export default Navbar
