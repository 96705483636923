import {
  ScheduleComponent,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  Resize,
  DragAndDrop,
  MonthAgenda,
  ICalendarExport,
  ViewsDirective,
  ViewDirective
} from '@syncfusion/ej2-react-schedule'
import { DropDownList } from '@syncfusion/ej2-react-dropdowns'
import { createElement } from '@syncfusion/ej2/base'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-hot-toast'
import { Header, Loader } from '../components'
import {
  getSchedules,
  createEvent,
  updateEvent,
  deleteEvent,
  getLessonsType
} from '../api'
import { toDateTimeMySql } from '../utils'

const handlePopupOpen = async (args) => {
  const types = await getLessonsType()
  if (args.type === 'Editor') {
    args.data.IsAllDay = false
    args.duration = 60
    if (!args.element.querySelector('.custom-field-row')) {
      let row = createElement('div', { className: 'custom-field-row' })
      let formElement = args.element.querySelector('.e-schedule-form')
      formElement.firstChild.insertBefore(
        row,
        formElement.firstChild.firstChild
      )
      let container = createElement('div', {
        className: 'custom-field-container'
      })
      let inputEle = createElement('input', {
        className: 'e-field',
        attrs: { name: 'EventType' }
      })
      container.appendChild(inputEle)
      row.appendChild(container)
      let dropDownList = new DropDownList({
        dataSource: types,
        fields: { text: 'text', value: 'value' },
        value: args.data.EventType,
        floatLabelType: 'Always',
        placeholder: 'Tipo clase'
      })
      row.setAttribute('style', 'margin-bottom:1rem')
      dropDownList.appendTo(inputEle)
      inputEle.setAttribute('name', 'EventType')
    }
  }
}

const Calendar = () => {
  const [exportCal, setExportCal] = useState({})
  const color = '#a27ec09c'
  const queryClient = useQueryClient()
  const { data, error, isLoading, isSuccess } = useQuery(
    ['schedules'],
    getSchedules
  )

  const { isLoading: isPostingEvent, mutate: mutateCreate } = useMutation(
    createEvent,
    {
      mutationKey: 'create',
      onSuccess: () => {
        queryClient.invalidateQueries(['schedules'])
        toast.success('Creación exitosa')
      },
      onError: (error) => {
        console.log(error)
        toast.error(
          `Error: ${error.code}\n Description: ${error.response.data.message}`
        )
      }
    }
  )

  const { isLoading: isUpdateEvent, mutate: mutateUpdate } = useMutation(
    (eventData) => updateEvent(eventData),
    {
      mutationKey: 'update',
      onSuccess: (data) => {
        queryClient.invalidateQueries(['schedules'])
        toast.success('Actualización exitosa')
      },
      onError: (error) => {
        console.log(error)
        toast.error(
          `Error: ${error.code}\n Description: ${error.response.data.message}`
        )
      }
    }
  )

  const { isLoading: isDeleteEvent, mutate: mutateDelete } = useMutation(
    (id) => deleteEvent(id),
    {
      mutationKey: 'delete',
      onSuccess: (data) => {
        queryClient.invalidateQueries(['schedules'])
        toast.success('Eliminación exitosa')
      },
      onError: (error) => {
        console.log(error)
        toast.error(
          `Error: ${error.code}\n Description: ${error.response.data.message}`
        )
      }
    }
  )

  const handleExport = () => {
    exportCal.exportToICalendar(`Horario Balance ${new Date().getFullYear()}`)
  }

  const handleSaveRecords = (args) => {
    switch (args.requestType) {
      case 'eventCreated':
        const event = {
          description: args?.data[0].Description,
          subject: args?.data[0].Subject,
          location: args?.data[0].Location,
          eventType: args?.data[0].EventType,
          isAllDay: args?.data[0].IsAllDay,
          day: new Date(args?.data[0].StartTime)
            .toLocaleDateString('en-US', { weekday: 'short' })
            .toString()
            .toUpperCase()
            .slice(0, 2),
          recurrenceRule: args?.data[0].RecurrenceRule,
          categoryColor:
            args?.data[0].Location.toLowerCase().trim() === 'salon 1'
              ? color
              : '#d613d478',
          startTime: toDateTimeMySql(args?.data[0].StartTime),
          endTime: toDateTimeMySql(args?.data[0].EndTime),
          maxCap:
            args?.data[0].EventType === 'Pole' ||
            args?.data[0].EventType === 'Aerial'
              ? 14
              : 20
        }
        mutateCreate(event)
        break
      case 'eventChanged':
        const id = args?.data[0].Id
        const updatedEvent = {
          subject: args?.data[0].Subject.split('-')[0].trim(),
          location: args?.data[0].Location.split(',')[0],
          eventType: args?.data[0].EventType,
          isAllDay: args?.data[0].IsAllDay,
          recurrenceRule: args?.data[0].RecurrenceRule,
          categoryColor:
            args?.data[0].Location.toLowerCase().trim() === 'salon 1'
              ? color
              : '#d613d478',
          startTime: toDateTimeMySql(args?.data[0].StartTime),
          endTime: toDateTimeMySql(args?.data[0].EndTime)
        }
        mutateUpdate({ id, updatedEvent })
        break
      case 'eventRemoved':
        mutateDelete(args.deletedRecords[0].Id)
        break
      default:
        break
    }
  }

  if (isLoading || isPostingEvent || isUpdateEvent || isDeleteEvent)
    return <Loader />

  return (
    <div className="md:mt-12 m-2 md:m-10 mt-24 p-5 md:p-10 bg-white rounded-3xl">
      <Header category="App" title="Horario" />
      <div className="mb-3">
        <button
          id="ics-export"
          title="Export"
          className="w-auto px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#a27ec0] rounded-md hover:bg-purple-400 focus:outline-none focus:bg-purple-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 mr-2"
          onClick={handleExport}
        >
          Exportar horario
        </button>
      </div>

      <ScheduleComponent
        ref={(schedule) => setExportCal(schedule)}
        height="650px"
        eventSettings={{
          dataSource: data.map((e) => {
            return {
              ...e,
              IsAllDay: e.IsAllDay === '0' ? false : true,
              Subject: `${e.EventType} - ${e.Subject}`,
              Location: `${e.Location}, Disponibles: ${e.MaxCap - e.CurrentCap}`
            }
          }),
          fields: {
            id: 'Id',
            subject: {
              name: 'Subject',
              title: 'Clase',
              validation: { required: true }
            },
            location: {
              name: 'Location',
              title: 'Lugar',
              validation: { required: true },
              default: 'Salon 1'
            },
            description: { name: 'Description', title: 'Descripción' },
            startTime: { name: 'StartTime', title: 'Inicio' },
            endTime: { name: 'EndTime', title: 'Fin' },
            isAllDay: { default: false }
          }
        }}
        dateFormat={'dd/MM/yyyy'}
        currentView="Month"
        workDays={[1, 2, 3, 4, 5, 6]}
        showWeekend={false}
        workHours={{
          highlight: true,
          start: '09:00',
          end: '22:00'
        }}
        popupOpen={handlePopupOpen}
        showQuickInfo={false}
        showWeekNumber={true}
        allowDragAndDrop={false}
        actionComplete={handleSaveRecords}
        eventRendered={(args) =>
          (args.element.style.backgroundColor = args.data.CategoryColor)
        }
      >
        <ViewsDirective>
          <ViewDirective option="WorkWeek" />
          <ViewDirective option="Month" />
          <ViewDirective option="Agenda" allowVirtualScrolling />
          <ViewDirective option="MonthAgenda" />
        </ViewsDirective>
        <Inject
          services={[
            WorkWeek,
            Month,
            Agenda,
            MonthAgenda,
            Resize,
            DragAndDrop,
            ICalendarExport
          ]}
        />
      </ScheduleComponent>
    </div>
  )
}

export default Calendar
