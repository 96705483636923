import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useQueryClient } from 'react-query'
import { useFormik } from 'formik'
import * as yup from 'yup'
import toast from 'react-hot-toast'
import { useDataToFormik } from '../hooks'
import FormByComponent from './FormByComponent'
import { formikSubmit } from '../utils'

const ModalForm = ({
  isOpen,
  setIsOpen,
  dataFields,
  componentName,
  headerText
}) => {
  const { initialValues, validationSchema } = useDataToFormik(dataFields)
  const [isSubmit, setIsSubmit] = useState(false)
  const queryClient = useQueryClient()

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: yup.object(validationSchema),
    onSubmit: async (values) => {
      try {
        setIsSubmit(true)
        const response = await formikSubmit(values, componentName)
        if (response?.result?.id) toast.success('Registro creado')
        if (response?.status === 422)
          // console.log(values)
          // const response = { message: 'test' }
          toast.error(JSON.stringify(response.message))
      } catch (error) {
        toast.error(error)
      } finally {
        setIsSubmit(false)
        queryClient.invalidateQueries()
        closeModal()
      }
    }
  })

  const closeModal = () => {
    formik.resetForm()
    setIsOpen(false)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Crear nuevo {headerText}
                  </Dialog.Title>
                  <div className="mt-2">
                    <FormByComponent
                      id={componentName}
                      dataFormik={formik}
                      handleModal={closeModal}
                      isSubmit={isSubmit}
                      inputSchema={dataFields}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ModalForm
