import { BsBoxSeam } from 'react-icons/bs'
import { MdOutlineSupervisorAccount } from 'react-icons/md'
import { useQuery } from 'react-query'
import { getDataCustomers, getPackages } from '../api'
import { Loader, Card } from '../components'
import { earningData } from '../data/dummy'
import { getMonth } from '../utils'

const Ecommerce = () => {
  const { data, isLoading, isSuccess } = useQuery(
    ['dataCustomers'],
    getDataCustomers
  )
  const { data: packages, isLoading: packagesIsLoading } = useQuery(
    ['dataPackages'],
    getPackages
  )

  if (isLoading || packagesIsLoading) return <Loader />

  return (
    <div className="mt-12">
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold text-gray-400">
                Ingreso {getMonth(new Date().getMonth())}
              </p>
              <p className="text-3xl">
                ${data.reduce((total, current) => total + +current.total, 0)}
              </p>
            </div>
          </div>
          {/* <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Download"
              borderRadius="10px"
              size="md"
            />
          </div> */}
        </div>
        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          {isSuccess && (
            <>
              <Card
                title={'Clientes activos'}
                amount={data.length}
                iconColor={'#03C9D7'}
                iconBg={'#E5FAFB'}
                icon={<MdOutlineSupervisorAccount />}
                pcColor={'red-600'}
                percentage={''}
              />
              <Card
                title={'Paquetes en lista'}
                amount={packages.length}
                iconColor={'#03C9D7'}
                iconBg={'#E5FAFB'}
                icon={<BsBoxSeam />}
                pcColor={'red-600'}
                percentage={''}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Ecommerce
