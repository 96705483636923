import { useEffect } from 'react'
import { translate } from '../utils/dictionary'

const FormByComponent = ({
  id: componentName,
  dataFormik: formik,
  handleModal: closeModal,
  isSubmit,
  inputSchema
}) => {
  const fields = Object.keys(formik.values)
  const types = inputSchema.filter((e) => e?.required && e?.type)
  if (componentName === 'packages') {
    types.unshift({
      field: 'type',
      headerText: 'Tipo',
      textAlign: 'Left',
      width: '100',
      required: true,
      type: 'select'
    })
  }

  const pole = document.getElementById('num_classes')
  const aerial = document.getElementById('num_classes_a')
  const comp = document.getElementById('num_comp_classes')

  // Parche de parche Fix pocho para arreglar detalle de tipos de paquetes
  useEffect(() => {
    if (formik.values.type === 'Pole') {
      const aerial = document.getElementById('num_classes_a')
      if (aerial !== null) {
        pole.readOnly = false
        pole.removeAttribute('disabled')
        pole.style.setProperty('background-color', 'white')
        comp.readOnly = false
        comp.removeAttribute('disabled')
        comp.style.setProperty('background-color', 'white')
        aerial.readOnly = true
        aerial.setAttribute('disabled', true)
        aerial.style.setProperty('background-color', 'lightGray')
        formik.setFieldValue('num_classes_a', '0')
      }
    }
    if (formik.values.type === 'Aerial') {
      const pole = document.getElementById('num_classes')
      if (pole !== null) {
        aerial.readOnly = false
        aerial.removeAttribute('disabled')
        aerial.style.setProperty('background-color', 'white')
        comp.readOnly = false
        comp.removeAttribute('disabled')
        comp.style.setProperty('background-color', 'white')
        pole.readOnly = true
        pole.setAttribute('disabled', true)
        pole.style.setProperty('background-color', 'lightGray')
        formik.setFieldValue('num_classes', '0')
      }
    }
    if (formik.values.type === 'PoleAerial') {
      pole.readOnly = false
      pole.removeAttribute('disabled')
      pole.style.setProperty('background-color', 'white')
      aerial.readOnly = false
      aerial.removeAttribute('disabled')
      aerial.style.setProperty('background-color', 'white')
      comp.readOnly = true
      comp.setAttribute('disabled', true)
      comp.style.setProperty('background-color', 'lightGray')
      formik.setFieldValue('num_comp_classes', '0')
    }
    if (formik.values.type === 'Complementaria') {
      pole.readOnly = true
      pole.setAttribute('disabled', true)
      pole.style.setProperty('background-color', 'lightGray')
      aerial.readOnly = true
      aerial.setAttribute('disabled', true)
      aerial.style.setProperty('background-color', 'lightGray')
      comp.readOnly = false
      comp.removeAttribute('disabled')
      comp.style.setProperty('background-color', 'white')
      formik.setFieldValue('num_classes', '0')
      formik.setFieldValue('num_classes_a', '0')
    }
    if (formik.values.type === 'Libre') {
      const $select = document.getElementById('type')
      const $label = document.querySelector('label[for="num_classes"]')
      $label.textContent = 'Número de clases'
      $select.disabled = true
      aerial != null && aerial.parentNode.remove()
      comp != null && comp.parentNode.remove()
      formik.setFieldValue('num_classes_a', '0')
      formik.setFieldValue('num_comp_classes', '0')
    }
  }, [formik.values])
  // fin pochillo

  return (
    <form id={componentName} onSubmit={formik.handleSubmit} autoComplete="off">
      <div className="shadow overflow-hidden sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            {types.map((elem, index) => (
              <div key={index} className="col-span-6 sm:col-span-6">
                {elem.type === 'text' ? (
                  <>
                    <label
                      htmlFor={elem.field}
                      className="block text-sm font-medium text-gray-700"
                    >
                      {translate(elem.field)}
                    </label>
                    <input
                      type={elem.type}
                      name={elem.field}
                      id={elem.field}
                      className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                      onChange={formik.handleChange}
                      value={formik.values[elem.field]}
                    />
                  </>
                ) : (
                  <>
                    <label
                      htmlFor={elem.field}
                      className="block text-sm font-medium text-gray-700"
                    >
                      {translate(elem.field)}
                    </label>
                    <select
                      name={elem.field}
                      id={elem.field}
                      className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                      onChange={formik.handleChange}
                      defaultValue={'0'}
                    >
                      <option value="0" disabled>
                        Seleccionar...
                      </option>
                      <option value="Pole">Pole + Complementaria</option>
                      <option value="Aerial">Aerial + Complementaria</option>
                      <option value="PoleAerial">Pole + Aerial</option>
                      <option value="Complementaria">Complementaria</option>
                      <option value="Libre">Libre</option>
                    </select>
                  </>
                )}
                {formik.touched[elem.field] && formik.errors[elem.field] ? (
                  <span className="text-xs text-red-500 flex justify-end">
                    {formik.errors[elem.field]}
                  </span>
                ) : null}
              </div>
            ))}
          </div>
        </div>
        <div className="mt-4 flex justify-end gap-4 p-4">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
            onClick={closeModal}
          >
            Cancelar
          </button>
          <button
            type="submit"
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#a27ec0] text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:w-auto sm:text-sm ${
              isSubmit
                ? 'disabled:bg-gray-300 cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            disabled={isSubmit}
          >
            Guardar
          </button>
        </div>
      </div>
    </form>
  )
}

export default FormByComponent
