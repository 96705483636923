import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { getInitialStateObject } from '../utils'

const useDataToFormik = (args) => {
  const [initialValues, setInitialValues] = useState({})
  const [validationSchema, setValidationSchema] = useState({})

  const validation = args.map((i) => {
    if (i.type === 'email') {
      return {
        ...i,
        validation: yup
          .string()
          .email('Correo electrónico inválido')
          .required('Campo requerido')
      }
    } else {
      return { ...i, validation: yup.string().required('Campo requerido') }
    }
  })

  useEffect(() => {
    setInitialValues(getInitialStateObject(args))
    setValidationSchema(getInitialStateObject(validation, true))
  }, [])

  return { initialValues, validationSchema }
}

export default useDataToFormik
