import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getSubscriptions } from '../api'
import { CardSubscription, Header, Loader } from '../components'

const SubscriptionDetail = () => {
  let customer
  const { id } = useParams()
  const { data, isLoading, isSuccess } = useQuery(
    ['subscriptions-id'],
    getSubscriptions
  )

  if (isLoading) return <Loader />

  if (isSuccess) {
    customer = data.filter((e) => e.id.toString() === id.toString())
    console.log(customer[0])
  }

  return (
    <div className="mt-20 md:mt-12 m-2 md:m-10 p-5 md:p-10 bg-white rounded-3xl">
      <Header category="Módulo" title="Clases y horarios" />
      {isSuccess && (
        <>
          <h3 className="text-2xl md:text-3xl lg:text-4xl text-gray-700 font-bold mb-6 ml-3">
            {customer[0].name}
          </h3>

          {customer[0].package.map((p) => (
            <h3
              key={p.id}
              className="text-lg md:text-2xl lg:text-3xl text-gray-700 font-bold ml-3"
            >
              {p.name}
            </h3>
          ))}

          <ol className="flex flex-col items-center justify-center mt-3 md:mt-6">
            {customer[0].schedule.map((s) => (
              <CardSubscription key={s.id} {...s} />
            ))}
          </ol>
        </>
      )}
    </div>
  )
}

export default SubscriptionDetail
